import '/@front:css/style.css'
import 'floating-vue/dist/style.css'

import FloatingVue from 'floating-vue'
import { i18nVue } from 'laravel-vue-i18n'
import { createApp, defineAsyncComponent } from 'vue'

import App from './App.vue'
import { router } from './shared/router'

if ('virtualKeyboard' in navigator) {
  // do not take the virtual keyboard into
  // account on the viewport units (dvh)
  (navigator as any).virtualKeyboard.overlaysContent = true
}

const app = createApp(App as any)

app.use(router)

app.use(i18nVue, {
  resolve: async (lang: string) => {
    const langs = import.meta.glob('../lang/*.json')

    const identifier = lang
    let path = `../lang/${identifier}.json`

    const [ locale, tenantSlug ] = lang.split('-')
    if (tenantSlug) {
      const tenantPath = `../lang/${locale}-${tenantSlug}.json`
      if (langs[path]) {
        path = tenantPath
      }
    }

    return await langs[path]()
  },
})

app.use(FloatingVue, {
  themes: {
    'info': {
      $extend: 'dropdown',
    },
  },
})

app.component('v-icon', defineAsyncComponent(() => import('./components/VIcon.vue')))
app.component('v-info', defineAsyncComponent(() => import('./components/VInfo.vue')))
app.component('v-title', defineAsyncComponent(() => import('./components/VTitle.vue')))
app.component('sidebar-kpi-progress', defineAsyncComponent(() => import('./components/SidebarKpiProgress.vue')))
app.component('code-input', defineAsyncComponent(() => import('./components/CodeInput.vue')))
app.component('score-bar', defineAsyncComponent(() => import('./components/ScoreBar.vue')))
app.component('peers-required', defineAsyncComponent(() => import('./components/PeersRequired.vue')))

app.mount('#selfie-app')
