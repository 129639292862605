import { createRouter, createWebHistory } from 'vue-router'

import routes from './routes'

const router = createRouter({
  history: createWebHistory('/a/'),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})

export { router }
