<template>
  <router-view></router-view>

  <div class="flits">
    <TransitionGroup name="flits">
      <div class="message" v-for="flits in messages" :class="flits.level" :key="flits.id">
        <p>{{ flits.message }}</p>
      </div>
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
import { provide } from 'vue'

import { messages } from '/@front/shared/flits'

provide('appName', _state.app?.title ?? 'Selfie360')
provide('scorePipDensity', 20)
provide('sliderOptions', {
  pips: {
    mode: 'range',
    density: 20
  },
  step: 5,
  tooltips: false,
})
</script>
