export default [
  {
    path: '/',
    name: 'splash',
    redirect: {
      name: 'participant-login',
    },
  },
  {
    path: '/participant/login',
    name: 'participant-login',
    component: () => import('/@front/views/participant/Login.vue'),
  },
  {
    path: '/participant',
    name: 'participant',
    component: () => import('/@front/views/Participant.vue'),
    children: [
      {
        path: '',
        name: 'participant-intake',
        component: () => import('/@front/views/participant/Intake.vue'),
        children: [
          {
            path: 'language',
            name: 'participant-select-language',
            component: () => import('/@front/views/participant/Language.vue'),
          },
          {
            path: 'intro',
            name: 'participant-intro',
            component: () => import('/@front/views/participant/Intro.vue'),
          },
          {
            path: 'profile',
            name: 'participant-profile',
            component: () => import('/@front/views/participant/Profile.vue'),
          },
          {
            path: 'role',
            name: 'participant-role',
            component: () => import('/@front/views/participant/Role.vue'),
          },
          {
            path: 'kpis',
            name: 'participant-kpis',
            component: () => import('/@front/views/participant/Kpis.vue'),
          },
          {
            path: 'getready',
            name: 'participant-getready',
            component: () => import('/@front/views/participant/GetReady.vue'),
          },
        ],
      },
      {
        path: 'survey',
        name: 'participant-survey',
        component: () => import('/@front/views/participant/Survey.vue'),
        children: [
          {
            path: 'statements/x/:id',
            name: 'participant-statements-view-single',
            component: () => import('/@front/views/shared/survey/Single.vue'),
          },
          {
            path: 'statements/y',
            name: 'participant-statements-view-batch',
            component: () => import('/@front/views/shared/survey/Batch.vue'),
          },
        ],
      },
      {
        path: 'results',
        component: () => import('/@front/views/participant/Results.vue'),
        children: [
          {
            path: '',
            name: 'results',
            component: () => import('/@front/views/participant/results/YourResults.vue'),
          },
          {
            path: 'peer-consensus',
            name: 'results-peer-consensus',
            component: () => import('/@front/views/participant/results/Consensus.vue'),
          },
          {
            path: 'development-overview',
            name: 'results-development-overview',
            component: () => import('/@front/views/participant/results/DevelopmentOverview.vue'),
          },
          {
            path: 'comments',
            name: 'results-comments',
            component: () => import('/@front/views/participant/results/Comments.vue'),
          },
          {
            path: 'kpi/:kpi',
            name: 'results-kpi',
            component: () => import('/@front/views/participant/results/Kpi.vue'),
          },
          {
            path: 'invite-peers',
            name: 'invite-peers',
            component: () => import('/@front/views/participant/Peers.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/peer/login/:code',
    name: 'peer-login',
    component: () => import('/@front/views/peer/Login.vue'),
  },
  {
    path: '/peer/language',
    name: 'peer-language',
    component: () => import('/@front/views/peer/Language.vue'),
  },
  {
    path: '/peer',
    name: 'peer',
    component: () => import('/@front/views/Peer.vue'),
    children: [
      {
        path: '/peer/intro',
        name: 'peer-intro',
        component: () => import('/@front/views/peer/Intro.vue'),
      },
      {
        path: 'survey',
        name: 'peer-survey',
        component: () => import('/@front/views/peer/Survey.vue'),
        children: [
          {
            path: 'statements/x/:id',
            name: 'peer-statements-view-single',
            component: () => import('/@front/views/shared/survey/Single.vue'),
          },
          {
            path: 'statements/y',
            name: 'peer-statements-view-batch',
            component: () => import('/@front/views/shared/survey/Batch.vue'),
          },
          {
            path: 'feedback',
            name: 'peer-feedback',
            component: () => import('/@front/views/peer/Feedback.vue'),
          }
        ],
      },
      {
        path: 'results',
        name: 'peer-results',
        component: () => import('/@front/views/peer/Results.vue'),
      }
    ],
  },
]
